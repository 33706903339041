<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="800px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button @click="test">测 试</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="100px">

      <el-row>
        <el-col :span="16">
          <el-form-item label="数据源名称">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="类型" label-width="60px">
            <el-select v-model="form.type">
              <el-option label="MYSQL" value="MYSQL"></el-option>
              <el-option label="Greenplum" value="Greenplum"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="16">
          <el-form-item label="服务器地址">
            <el-input v-model="form.host" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="端口" label-width="60px">
            <el-input v-model="form.port" />
          </el-form-item>
        </el-col>
      </el-row>
      
      <el-form-item label="用户">
        <el-input v-model="form.user" />
      </el-form-item>

      <el-form-item label="密码">
        <el-input v-model="form.password" />
      </el-form-item>
      <el-form-item label="数据库名">
        <el-input v-model="form.database"></el-input>
      </el-form-item>

      <el-form-item label="描述">
        <el-input v-model="form.description" type="textarea" resize="none" :rows="3" />
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import _ from 'lodash';
export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {},
    }
  },

  methods: {
    show(initForm, options = {}) {
      this.form = _.assign({}, initForm, {});
      this.options = options;
      this.visible = true;
    },

    async confirmForm() {
      try {
        if(this.form.id) {
          await this.$api.execute('db_source.update', this.form.id, _.pick(this.form, ['name', 'type', 'host', 'port', 'database', 'user', 'password', 'description']));
        } else {
          await this.$api.execute('db_source.create', _.pick(this.form, ['name', 'type', 'host', 'port', 'database', 'user', 'password', 'description']));
        }
        this.$emit('success')
        this.visible = false;
      } catch(e) {
        console.log({ e })
      }
    },

    async test() {
      try {
        await this.$api.execute('db_source.connect', _.pick(this.form, ['type', 'host', 'port', 'database', 'user', 'password']));
        this.$message.success('连接成功')
      } catch(e) {
        this.$message.error(e.message);
      }
    }
  }
}
</script>